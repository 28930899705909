import Box from "@material-ui/core/Box"
import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Layout from "../components/Layout"
import Message from "../Analytics/Message"
import {
  ADMIN_ADVANCE_SIMULATION_STATE,
  ADMIN_GET_SEASONS_BEING_SIMULATED,
  ADMIN_GET_SEASONS_TO_SIMULATE,
  ADMIN_MAKE_PICKS_FOR_SIMULATED_SEASON,
  ADMIN_RESET_SIMULATION_STATE,
  ADMIN_SET_SEASON_TO_SIMULATE,
  ADMIN_UNSET_SEASON_FROM_SIMULATION,
} from "../queries"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { AdminSetSeasonToSimulate } from "../../../__generated__/AdminSetSeasonToSimulate"
import { AdminSetServiceScalingMutationVariables } from "../../../__generated__/AdminSetServiceScalingMutation"
import { AdminUnsetSeasonFromSimulation, AdminUnsetSeasonFromSimulationVariables } from "../../../__generated__/AdminUnsetSeasonFromSimulation"
import { AdminGetSeasonsToSimulate } from "../../../__generated__/AdminGetSeasonsToSimulate"
import {
  AdminGetSeasonsBeingSimulated,
  AdminGetSeasonsBeingSimulated_adminGetSeasonsBeingSimulated,
} from "../../../__generated__/AdminGetSeasonsBeingSimulated"
import LoadingView from "../../components/LoadingView"
import { AdminAdvanceSimulationState, AdminAdvanceSimulationStateVariables } from "../../../__generated__/AdminAdvanceSimulationState"
import { AdminResetSimulationState, AdminResetSimulationStateVariables } from "../../../__generated__/AdminResetSimulationState"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { AdminMakePicksForSimulatedSeason, AdminMakePicksForSimulatedSeasonVariables } from "../../../__generated__/AdminMakePicksForSimulatedSeason"

enum MutationCalled {
  advanceSimulation = "advanceSimulation",
  resetSimulation = "resetSimulation",
  stopSimulation = "stopSimulation",
  makePicks = "makePicks",
}

const useGameSimulationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      cursor: "pointer",
      "& > *": {
        borderBottom: "unset",
      },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
      width: "100% !important",
    },
    controlItemContainer: {
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    controlItem: {
      display: "flex",
      flexGrow: 1,
      padding: "0 0.5rem",
      justifyContent: "center",
    },
    useColumn: {
      display: "flex",
      flexDirection: "column",
    },
    currentStateContainer: {
      alignItems: "flex-start",
    },
  }),
)

type StylesType = ReturnType<typeof useGameSimulationStyles>

interface BaseProps {
  isLoading: boolean
  classes: StylesType
}
interface AlreadySimulatingSeasonsProps extends BaseProps {
  queryResult?: AdminGetSeasonsBeingSimulated
  stopSimulatingSeasonMutation: any
  advanceStateMutation: any
  resetStateMutation: any
  makePicksMutation: any
  setMutationCalled: React.Dispatch<React.SetStateAction<MutationCalled | undefined>>
}
interface SeasonsToSimulateProps extends BaseProps {
  queryResult?: AdminGetSeasonsToSimulate
  simulateSeasonMutation: any
}

interface SimulatedSeasonProps {
  classes: StylesType
  isLoading: boolean
  season: AdminGetSeasonsBeingSimulated_adminGetSeasonsBeingSimulated
  stopSimulatingSeasonMutation: any
  advanceStateMutation: any
  resetStateMutation: any
  makePicksMutation: any
  setMutationCalled: React.Dispatch<React.SetStateAction<MutationCalled | undefined>>
}

const SimulatedSeason: React.FC<SimulatedSeasonProps> = ({
  season,
  classes,
  isLoading,
  stopSimulatingSeasonMutation,
  advanceStateMutation,
  resetStateMutation,
  makePicksMutation,
  setMutationCalled,
}) => {
  const { id, productAbbrev } = season

  const handleStopSimulatingClick = () => {
    stopSimulatingSeasonMutation({
      variables: {
        seasonId: id,
      },
      refetchQueries: [{ query: ADMIN_GET_SEASONS_TO_SIMULATE }, { query: ADMIN_GET_SEASONS_BEING_SIMULATED }],
      awaitRefetchQueries: true,
    }).then(() => {
      setMutationCalled(MutationCalled.stopSimulation)
    })
  }

  const handleAdvanceStateClick = () => {
    advanceStateMutation({
      variables: {
        seasonId: id,
      },
      refetchQueries: [{ query: ADMIN_GET_SEASONS_BEING_SIMULATED }],
      awaitRefetchQueries: true,
    }).then(() => {
      setMutationCalled(MutationCalled.advanceSimulation)
    })
  }

  const handleMakePicksClick = () => {
    makePicksMutation({
      variables: {
        seasonId: id,
      },
      refetchQueries: [{ query: ADMIN_GET_SEASONS_BEING_SIMULATED }],
      awaitRefetchQueries: true,
    }).then(() => {
      setMutationCalled(MutationCalled.makePicks)
    })
  }

  const handleResetClick = () => {
    resetStateMutation({
      variables: {
        seasonId: id,
      },
      refetchQueries: [{ query: ADMIN_GET_SEASONS_BEING_SIMULATED }],
      awaitRefetchQueries: true,
    }).then(() => {
      setMutationCalled(MutationCalled.resetSimulation)
    })
  }

  if (isLoading) {
    return <LoadingView />
  }

  const currentSimulatedState = season.currentSimulatedState
  const gameInstance = season.gameInstance

  if (!currentSimulatedState) {
    return (
      <Box>
        Something went wrong, there's no current simulation state for {productAbbrev} - {id}
      </Box>
    )
  }

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>
            {productAbbrev} - {id}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.useColumn}>
          <Box className={classes.controlItemContainer}>
            <Box mb={3} className={classes.controlItem}>
              <Box mb={3} className={`${classes.controlItemContainer} ${classes.useColumn} ${classes.currentStateContainer}`}>
                <Box className={classes.controlItem}>
                  State {currentSimulatedState.state}: {currentSimulatedState.description}
                </Box>
                <Box className={classes.controlItem}>Current Period Order: {gameInstance.currentPeriod?.order}</Box>
              </Box>
              <Box className={classes.controlItemContainer}>
                <Box mb={3} className={classes.controlItem}>
                  <Button color="primary" variant="contained" onClick={handleAdvanceStateClick} disabled={isLoading}>
                    Advance State
                  </Button>
                </Box>
                <Box mb={3} className={classes.controlItem}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleMakePicksClick}
                    disabled={isLoading || currentSimulatedState.state === 1}
                  >
                    Make Picks For Everyone
                  </Button>
                </Box>
                <Box mb={3} className={classes.controlItem}>
                  <Button color="secondary" variant="contained" onClick={handleResetClick} disabled={isLoading}>
                    Reset Simulation
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.controlItemContainer}>
            <Box mb={3} flexGrow={0} className={classes.controlItem}>
              <Button color="secondary" variant="contained" onClick={handleStopSimulatingClick} disabled={isLoading}>
                Stop Simulating
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

const AlreadySimulatingSeasons: React.FC<AlreadySimulatingSeasonsProps> = ({
  isLoading,
  classes,
  queryResult,
  stopSimulatingSeasonMutation,
  advanceStateMutation,
  resetStateMutation,
  makePicksMutation,
  setMutationCalled,
}) => {
  return (
    <Box mb={3}>
      <FormControl fullWidth>
        <FormLabel id="seasons-radio-buttons-group-label">Already Simulating Seasons</FormLabel>
        {isLoading ? (
          <LoadingView />
        ) : (
          queryResult?.adminGetSeasonsBeingSimulated?.map((season) => {
            return (
              /testing/i.test(season.gameInstance.uid) && (
                <SimulatedSeason
                  key={season.id}
                  season={season}
                  classes={classes}
                  isLoading={isLoading}
                  stopSimulatingSeasonMutation={stopSimulatingSeasonMutation}
                  advanceStateMutation={advanceStateMutation}
                  resetStateMutation={resetStateMutation}
                  makePicksMutation={makePicksMutation}
                  setMutationCalled={setMutationCalled}
                />
              )
            )
            // return <FormControlLabel key={id} value={id} control={<Radio />} label={productAbbrev} checked={id === seasonIdToStopSimulating} />
          })
        )}
      </FormControl>
    </Box>
  )
}

const SeasonsToSimulate: React.FC<SeasonsToSimulateProps> = ({ isLoading, queryResult, simulateSeasonMutation, classes }) => {
  const [seasonIdToSimulate, setSeasonIdToSimulate] = useState<string | null>(null)

  const setSeatonIdToSimulateFromForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSeasonIdToSimulate((event.target as HTMLInputElement).value)
  }

  const handleClick = () => {
    if (!seasonIdToSimulate) {
      throw new Error(`No season id to simulate`)
    }
    simulateSeasonMutation({
      variables: {
        seasonId: seasonIdToSimulate,
      },
      refetchQueries: [{ query: ADMIN_GET_SEASONS_TO_SIMULATE }, { query: ADMIN_GET_SEASONS_BEING_SIMULATED }],
      awaitRefetchQueries: true,
    })
    setSeasonIdToSimulate(null)
  }

  const seasonIdToProductAbbrev: Record<string, string> = {}

  return (
    <Box mb={3}>
      <FormControl>
        <FormLabel id="seasons-radio-buttons-group-label">Seasons To Simulate</FormLabel>
        <RadioGroup aria-labelledby="seasons-radio-buttons-group-label" name="seasons-radio-buttons-group" onChange={setSeatonIdToSimulateFromForm}>
          {isLoading ? (
            <LoadingView />
          ) : (
            queryResult?.adminGetSeasonsToSimulate?.map((season) => {
              const { id, productAbbrev } = season
              seasonIdToProductAbbrev[id] = productAbbrev
              return <FormControlLabel key={id} value={id} control={<Radio />} label={productAbbrev} checked={id === seasonIdToSimulate} />
            })
          )}
        </RadioGroup>
      </FormControl>
      <Box mb={3}>
        <Button color="primary" variant="contained" onClick={handleClick} disabled={isLoading}>
          Simulate {seasonIdToSimulate && seasonIdToProductAbbrev[seasonIdToSimulate] ? seasonIdToProductAbbrev[seasonIdToSimulate] : ""}
        </Button>
      </Box>
    </Box>
  )
}

const GameSimulation: React.FC = () => {
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>(undefined)
  const [mutationCalled, setMutationCalled] = useState<MutationCalled | undefined>(undefined)

  const classes = useGameSimulationStyles()
  const [simulateSeasonMutation, { /*data: simulateSeasonData, error: simulateSeasonError,*/ loading: simulateSeasonLoading }] = useMutation<
    AdminSetSeasonToSimulate,
    AdminSetServiceScalingMutationVariables
  >(ADMIN_SET_SEASON_TO_SIMULATE)

  const [
    stopSimulatingSeasonMutation,
    { data: stopSimulationSeasonData, error: stopSimulationSeasonError, loading: stopSimulationSeasonLoading },
  ] = useMutation<AdminUnsetSeasonFromSimulation, AdminUnsetSeasonFromSimulationVariables>(ADMIN_UNSET_SEASON_FROM_SIMULATION)

  const [
    advanceStateMutation,
    { data: advanceStateMutationData, error: advanceStateMutationError, loading: advanceStateMutationLoading },
  ] = useMutation<AdminAdvanceSimulationState, AdminAdvanceSimulationStateVariables>(ADMIN_ADVANCE_SIMULATION_STATE)

  const [resetStateMutation, { data: resetStateMutationData, error: resetStateMutationError, loading: resetStateMutationLoading }] = useMutation<
    AdminResetSimulationState,
    AdminResetSimulationStateVariables
  >(ADMIN_RESET_SIMULATION_STATE)

  const [makePicksMutation, { data: makePicksMutationData, error: makePicksMutationError, loading: makePicksMutationLoading }] = useMutation<
    AdminMakePicksForSimulatedSeason,
    AdminMakePicksForSimulatedSeasonVariables
  >(ADMIN_MAKE_PICKS_FOR_SIMULATED_SEASON)

  const {
    data: alreadySimulatingSeasonsData,
    error: alreadySimulatingSeasonsError,
    loading: alreadySimulatingSeasonsLoading,
  } = useQuery<AdminGetSeasonsBeingSimulated>(ADMIN_GET_SEASONS_BEING_SIMULATED, { fetchPolicy: "network-only" })

  const { data: seasonsToSimulateData, error: seasonsToSimulateError, loading: seasonsToSimulateLoading } = useQuery<AdminGetSeasonsToSimulate>(
    ADMIN_GET_SEASONS_TO_SIMULATE,
    {
      fetchPolicy: "network-only",
    },
  )

  const handleSnackbarOnClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    setMutationCalled(undefined)
    setSnackbarMessage(undefined)
  }

  if (mutationCalled && !snackbarMessage) {
    switch (mutationCalled) {
      case MutationCalled.advanceSimulation:
        setSnackbarMessage(advanceStateMutationData?.adminAdvanceSimulationState)
        break
      case MutationCalled.resetSimulation:
        setSnackbarMessage(resetStateMutationData?.adminResetSimulationState)
        break
      case MutationCalled.stopSimulation:
        setSnackbarMessage(stopSimulationSeasonData?.adminUnsetSeasonFromSimulation)
        break
      case MutationCalled.makePicks:
        setSnackbarMessage(makePicksMutationData?.adminMakePicksForSimulatedSeason)
        break
      default:
        setSnackbarMessage(undefined)
    }
  }

  const isLoading =
    alreadySimulatingSeasonsLoading ||
    seasonsToSimulateLoading ||
    simulateSeasonLoading ||
    stopSimulationSeasonLoading ||
    advanceStateMutationLoading ||
    resetStateMutationLoading ||
    makePicksMutationLoading

  return (
    <Layout title={`Sim - Game Simulation`}>
      <AlreadySimulatingSeasons
        isLoading={isLoading}
        queryResult={alreadySimulatingSeasonsData}
        classes={classes}
        stopSimulatingSeasonMutation={stopSimulatingSeasonMutation}
        advanceStateMutation={advanceStateMutation}
        resetStateMutation={resetStateMutation}
        makePicksMutation={makePicksMutation}
        setMutationCalled={setMutationCalled}
      />
      {makePicksMutationError && <Message variant="ERROR">{makePicksMutationError.message}</Message>}
      {resetStateMutationError && <Message variant="ERROR">{resetStateMutationError.message}</Message>}
      {advanceStateMutationError && <Message variant="ERROR">{advanceStateMutationError.message}</Message>}
      {stopSimulationSeasonError && <Message variant="ERROR">{stopSimulationSeasonError.message}</Message>}
      {alreadySimulatingSeasonsError && <Message variant="ERROR">{alreadySimulatingSeasonsError.message}</Message>}
      {seasonsToSimulateError && <Message variant="ERROR">{seasonsToSimulateError.message}</Message>}
      <SeasonsToSimulate
        isLoading={isLoading}
        queryResult={seasonsToSimulateData}
        classes={classes}
        simulateSeasonMutation={simulateSeasonMutation}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={!!mutationCalled}
        onClose={handleSnackbarOnClose}
        autoHideDuration={2000}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#558b2f",
          }}
          message={snackbarMessage}
          action={
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarOnClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </Snackbar>
    </Layout>
  )
}

export default GameSimulation
